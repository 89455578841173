<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li>
        <router-link to="/">Domov</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li v-if="type === 'CANCELED'">
        <router-link :to="{name: 'CanceledReservations'}">Odpovedni prevozi</router-link>
        <span class="vs-breadcrum--separator">/</span></li>
      <li v-if="type === 'DECLINED'">
        <router-link :to="{name: 'DeclinedReservations'}">Zavrnjeni prevozi</router-link>
        <span class="vs-breadcrum--separator">/</span></li>


      <li aria-current="page" class="active" v-if="type === 'CANCELED'">
        <span v-if="$route.params.reservationId">Urejanje odpovedanega prevoza</span>
        <span v-if="!$route.params.reservationId">Dodajanje odpovedanega prevoza</span>
      </li>
      <li aria-current="page" class="active" v-if="type === 'DECLINED'">
        <span v-if="$route.params.reservationId">Urejanje zavrnjenega prevoza</span>
        <span v-if="!$route.params.reservationId">Dodajanje zavrnjenega prevoza</span>
      </li>
    </vs-breadcrumb>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">

        <div class="grid grid-cols-1 mb-10">
          <div>
            <h1 v-if="type === 'CANCELED'">
              <span v-if="$route.params.reservationId">Urejanje odpovedanega prevoza</span>
              <span v-if="!$route.params.reservationId">Dodajanje odpovedanega prevoza</span>
            </h1>
            <h1 v-if="type === 'DECLINED'">
              <span v-if="$route.params.reservationId">Urejanje zavrnjenega prevoza</span>
              <span v-if="!$route.params.reservationId">Dodajanje zavrnjenega prevoza</span>
            </h1>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <div>
            <label class="vs-input--label">Datum</label>
            <datepicker placeholder="Datum" :language="slSI" :format="customDateFormatter"
                        :monday-first="true"
                        v-model="reservationData.date"></datepicker>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5" v-if="$globalFunctions.isAdmin()">
          <div>
            <label class="vs-input--label">Zapis za enoto</label>
            <v-select id="unit" :options="unitsData" class="" @input="onUnitChange" style="min-width: 300px"
                      v-model="unitId" placeholder="Zapis za enoto"
                      :reduce="item => item.id"
                      v-if="unitsData"
                      :clearable="false"
                      label="name">

              <template v-slot:option="option">
                <vs-list-item
                  :title="option.name"
                  :subtitle="option.coveredMunicipalities">
                  <template slot="avatar" v-if="option.name">
                    <vs-avatar size="large"
                               :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                               class="mr-5"/>
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:selected-option="option">
                <vs-list-item
                  :title="option.name"
                  :subtitle="option.coveredMunicipalities">
                  <template slot="avatar" v-if="option.name">
                    <vs-avatar size="large"
                               :text="option && option.name && option.name.toString().substr(0,1) + (option.name.toString().match(/\d/g) ? option.name.toString().match(/\d/g).join('') : '')"
                               class="mr-5"/>
                  </template>
                </vs-list-item>
              </template>

              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
              </template>
            </v-select>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <label for="reservation_coordinated" class="vs-input--label">Obstoječ uporabnik <small>(označi z DA, če je
            uporabnik že v naši bazi)</small></label>
          <vs-switch color="success" v-model="existingUser"
                     id="reservation_coordinated">
            <span slot="on">DA</span>
            <span slot="off">NE</span>
          </vs-switch>
        </div>

        <div class="grid grid-cols-1 mt-5" v-if="existingUser && customersData">
          <label for="reservation_coordinated" class="vs-input--label">Uporabnik</label>

          <v-select :filter="fuseSearch" :options="customersData" class="" v-model="customer"
                    :reduce="item => item.id"
                    placeholder="Uporabnik"
                    :getOptionLabel="option => option.first_name + '' + option.last_name"
                    @input="onCustomerChange">
            <template v-slot:option="option">
              <vs-list-item
                :title="option.first_name + ' ' + option.last_name"
                :subtitle="option.addresses ? option.addresses.quick_search : ''">
                <template slot="avatar">
                  <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam"
                             :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                </template>
              </vs-list-item>
            </template>

            <template v-slot:selected-option="option">
              <vs-list-item
                class="d-block"
                :title="option.first_name + ' ' + option.last_name"
                :subtitle="option.addresses ? option.addresses.quick_search : ''">
                <template slot="avatar">
                  <vs-avatar class="mr-5" size="60px" :src="option.gender === 'male' ? sir : madam"
                             :color="option.gender === 'male' ? '#dff8ff' : '#ffffdf'"/>
                </template>
              </vs-list-item>
            </template>

            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
              </template>
              <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
            </template>
          </v-select>
        </div>


        <div class="grid grid-cols-1 mt-5" v-if="!existingUser">
          <div>
            <vs-input class="w-full" label="Ime uporabnika" type="text"
                      v-model="reservationData.customer_name"/>
          </div>
        </div>

        <div class="grid grid-cols-1 mt-5">
          <div>
            <label class="vs-input--label">Opis</label>
            <vs-textarea class="w-full" rows="10"
                         v-model="reservationData.reason"/>
          </div>
        </div>


        <div class="flex mt-10 mb-20">
          <div class="w-1/3">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
              <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2">Prekliči</vs-button>
            </vs-col>
          </div>
          <div class="w-2/3">
            <div class="flex">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="reservationData.id">
                <vs-button size="large" color="danger" @click="beforeDeleteCanceledOrDeclinedReservation" icon="delete">Izbriši
                </vs-button>
              </vs-col>

              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button size="large" color="success" icon="save" @click="saveCanceledOrDeclinedReservation()"
                           v-if="!reservationData.id">Shrani
                </vs-button>
                <vs-button size="large" color="success" icon="save" @click="updateCanceledOrDeclinedReservation()"
                           v-if="reservationData.id">Shrani spremembe
                </vs-button>
              </vs-col>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import PrivateDonationsTable from "../../components/Sopotniki/donations/PrivateDonationsTable";
import {slSI} from "vuejs-datepicker/dist/locale";
import sir from "@/assets/images/portrait/sir.svg";
import madam from "@/assets/images/portrait/madam.svg";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import Fuse from "fuse.js";


export default {
  name: 'CanceledOrDeclinedReservationEdit',

  components: {
    'v-select': vSelect,
    Datepicker,
    PrivateDonationsTable
  },

  data() {
    return {
      sir,
      madam,
      type: this.$route.path.includes('odpovedan') ? 'CANCELED' : 'DECLINED',
      reservationItem: this.$route.params.reservationItem, // data passed from reservation list
      unitsData: null,
      unitId: this.$globalFunctions.getCurrentUnit().id,
      reservationData: {
        "unitId": null,
        "customer_name": null,
        "customerId": null,
        "reason": null,
        "date": new Date(),
        "type": this.$route.path.includes('odpovedan') ? 'CANCELED' : 'DECLINED'
      },
      customersData: null,
      customer: null,
      slSI: slSI,
      existingUser: false,
    }
  },

  computed: {},

  mounted() {
    const _this = this;

    _this.$vs.loading();

    let p1 = this.getUnits();
    Promise.all([p1]).then(() => {
      if (_this.$route.params.reservationId) {
        _this.getCanceledOrDeclinedReservation();
      } else if(_this.reservationItem) { //if arriving from reservations list
        _this.reservationData.unitId = _this.reservationItem.unit_id;
        _this.reservationData.customerId = _this.reservationItem.customer_id;
        _this.reservationData.date = _this.reservationItem.departure_time;

        _this.unitId = _this.reservationItem.unit_id;
        _this.existingUser = true;
        _this.customer = _this.reservationItem.customer_id;
        _this.getCustomers();
      } else {
        _this.getCustomers();
      }

      _this.$vs.loading.close();
    });

  },

  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    next();
  },

  methods: {
    onUnitChange() {
      const _this = this;
      _this.getCustomers();
      if (_this.$route.params.reservationId) {
        _this.getCanceledOrDeclinedReservation();
      }
    },

    onCustomerChange() {
      const _this = this;
      let thisCustomer = _.filter(_this.customersData, {id: _this.customer})[0];

      _this.reservationData.customer_name = thisCustomer.first_name + ' ' + thisCustomer.last_name;
    },

    async getUnits() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units')
        .then((res) => {
          _this.unitsData = _.filter(res.data.data, {disabled: 0});
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    async getCanceledOrDeclinedReservation() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations/' + _this.$route.params.reservationId)
        .then((res) => {
          _this.reservationData = res.data.data;
          _this.unitId = res.data.data.unit.id;
          _this.existingUser = res.data.data.customer ? true : false;
          _this.customer = res.data.data.customer ? res.data.data.customer.id : null;
          _this.getCustomers();
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getCustomers() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.unitId)
        .then((res) => {
          _this.customersData = res.data.data;
          if(_this.reservationData.customer) {
            _this.customer = _this.reservationData.customer.id;
          }
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    customDateFormatter(date) {
      return this.moment(date).format('DD. MM. YYYY');
    },

    beforeDeleteCanceledOrDeclinedReservation() {
      const _this = this;

      Vue.$confirm({
        title: 'Ali ste prepričani, da želite izbrisati ta vnos?',
        message: 'Po potrditvi bo vnos trajno izbrisan.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.$vs.loading();
            _this.deleteCanceledOrDeclinedReservation();
          }
        }
      });


    },

    deleteCanceledOrDeclinedReservation() {
      const _this = this;

      Vue.prototype.$http.delete(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations/' + _this.$route.params.reservationId)
        .then((res) => {
          _this.$vs.loading.close();
          if(_this.type === 'CANCELED') {
            _this.$router.push({name: 'CanceledReservations'});
          } else if(_this.type === 'DECLINED') {
            _this.$router.push({name: 'DeclinedReservations'});
          }
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    saveCanceledOrDeclinedReservation() {
      const _this = this;

      _this.$vs.loading();

      let reservationData = {
        "unitId": _this.unitId,
        "customer_name": _this.reservationData.customer_name,
        "customerId": _this.existingUser ? _this.customer : null,
        "reason": _this.reservationData.reason,
        "date": _this.moment(_this.reservationData.date).valueOf(),
        "type": _this.type
      };

      Vue.prototype.$http.post(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations', reservationData)
        .then((res) => {
          _this.$vs.loading.close();

          if(_this.reservationItem) {
            Vue.$confirm({
              title: 'Vnos ' + (_this.type === 'CANCELED' ? 'odpovedanega' : 'zavrnjenega') + ' prevoza ste izvedli preko prenosa podatkov iz obstoječe rezeracije v sistemu.',
              message: 'Ali želite to rezervacijo izbrisati?',
              button: {
                yes: 'Izbriši',
                no: 'Prekliči'
              },
              callback: confirm => {
                console.log("confirm", confirm);
                if (confirm) {

                  _this.$vs.loading();

                  Vue.prototype.$http.delete(Vue.prototype.$config.api.productionOld + 'reservations/' + _this.reservationItem.id)
                    .then((res) => {

                      _this.$vs.loading.close();
                      if(_this.type === 'CANCELED') {
                        _this.$router.push({name: 'CanceledReservations'});
                      } else if(_this.type === 'DECLINED') {
                        _this.$router.push({name: 'DeclinedReservations'});
                      }

                    })
                    .catch((error) => {
                      console.log("error", error);
                      _this.$vs.loading.close();
                      return false
                    });

                }
              }
            });
          } else {
            if(_this.type === 'CANCELED') {
              _this.$router.push({name: 'CanceledReservations'});
            } else if(_this.type === 'DECLINED') {
              _this.$router.push({name: 'DeclinedReservations'});
            }
          }


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    updateCanceledOrDeclinedReservation() {
      const _this = this;

      _this.$vs.loading();

      let reservationData = {
        "unitId": _this.unitId,
        "customer_name": _this.reservationData.customer_name,
        "customerId": _this.existingUser ? _this.customer : null,
        "reason": _this.reservationData.reason,
        "date": _this.moment(_this.reservationData.date).valueOf(),
        "type": _this.type
      };

      Vue.prototype.$http.put(Vue.prototype.$config.api.sopotnikiVPS + 'canceled-declined-reservations/' + _this.reservationData.id, reservationData)
        .then((res) => {
          _this.$vs.loading.close();
          if(_this.type === 'CANCELED') {
            _this.$router.push({name: 'CanceledReservations'});
          } else if(_this.type === 'DECLINED') {
            _this.$router.push({name: 'DeclinedReservations'});
          }

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["first_name", "last_name", "addresses.quick_search"],
        minMatchCharLength: 3,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },
  }
}
</script>

